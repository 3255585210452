<template>
<div>
  <div class="sports_links">
    <button class="bw2" v-if="postion === 1" :class="{'sports_link_active': disp === sportsConst.GAME_DISPLAYPOSITION_1x2}"
            @click="changeSportsDisplay(sportsConst.GAME_DISPLAYPOSITION_1x2)">크로스</button>
    <!--    <button class="bw4 ml-5" :class="{'sports_link_active': disp === sportsConst.GAME_DISPLAYPOSITION_HANDICAPOVERUNDER}"-->
    <!--            @click="changeSportsDisplay(sportsConst.GAME_DISPLAYPOSITION_HANDICAPOVERUNDER)">핸디캡</button>-->
    <button class="bw2" v-if="postion === 1" :class="{'sports_link_active': disp === sportsConst.GAME_DISPLAYPOSITION_SPECIAL}"
            @click="changeSportsDisplay(sportsConst.GAME_DISPLAYPOSITION_SPECIAL)">스페셜</button>
    <!--    <button class="btn02 bw3" :class="{'gt_type_active': disp === sportsConst.GAME_DISPLAYPOSITION_LIVE}"-->
    <!--            @click="changeSportsDisplay(sportsConst.GAME_DISPLAYPOSITION_LIVE)"><i class="fa fa-television"></i> Live</button>-->
  </div>
</div>

</template>

<script>
  import sportsConst from "../../common/sportsConst";
  import {RECEIVE_DISPLAY_POSTION} from "../../store/mutation-types";
  import {getSportsGameCountGroupByType} from "../../network/sportsRequest";

  export default {
    name: "SportsLinksComp",
    props: {
      displayPosition: {
        type: Number,
        default() {
          return sportsConst.GAME_DISPLAYPOSITION_1x2;
        }
      },
      postion:{
        type: Number,
        default() {
          return 1;
        }
      }
    },
    data(){
      return {
        sportsConst,
        disp: this.displayPosition ? this.displayPosition : sportsConst.GAME_DISPLAYPOSITION_1x2,
        soccer: 0,
        baseball: 0,
        basketball: 0,
        volleyball: 0,
        football: 0,
        hockey: 0,
        esports: 0,
        tennis: 0,
        alltypecount: 0,
        selected:sportsConst.GAME_TYPE_ALL,
      }
    },
    methods:{
      changeSportsDisplay(disp) {
        //SportsOriginal.vue -> $on
        this.disp = disp
        this.$store.commit(RECEIVE_DISPLAY_POSTION, 0);
        this.$store.commit(RECEIVE_DISPLAY_POSTION, disp);
      },
      selectedType(type) {
        this.selected = type
        this.$store.state.selectedGameType = type
      }
    },
    created() {
      getSportsGameCountGroupByType(sportsConst.INPLAY_NOT).then(res => {

        if (res.data.success) {
          let map = res.data.data;
          this.soccer = map[sportsConst.GAME_TYPE_SOCCER] !== undefined ? map[sportsConst.GAME_TYPE_SOCCER] : 0
          this.baseball = map[sportsConst.GAME_TYPE_BASEBALL] !== undefined ? map[sportsConst.GAME_TYPE_BASEBALL] : 0
          this.basketball = map[sportsConst.GAME_TYPE_BASKETBALL] !== undefined ? map[sportsConst.GAME_TYPE_BASKETBALL] : 0
          this.volleyball = map[sportsConst.GAME_TYPE_VOLLEYBALL] !== undefined ? map[sportsConst.GAME_TYPE_VOLLEYBALL] : 0
          this.hockey = map[sportsConst.GAME_TYPE_HOCKEY] !== undefined ? map[sportsConst.GAME_TYPE_HOCKEY] : 0
          this.football = map[sportsConst.GAME_TYPE_USA_FOOTBALL] !== undefined ? map[sportsConst.GAME_TYPE_USA_FOOTBALL] : 0
          this.esports = map[sportsConst.GAME_TYPE_ESPORTS] !== undefined ? map[sportsConst.GAME_TYPE_ESPORTS] : 0
          this.tennis = map[sportsConst.GAME_TYPE_TENNIS] !== undefined ? map[sportsConst.GAME_TYPE_TENNIS] : 0
          this.alltypecount = this.soccer + this.baseball + this.basketball + this.volleyball + this.hockey + this.football + this.esports + this.tennis
        }
      })
    }
  }
</script>

<style scoped>
  .sports_types {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin: 15px 0;
  }

  .sports_types button {
    width: 12.3%;
    height: 32px;
    line-height: 32px;
    background: #2c2b30;
    padding: 0 10px;
    font-weight: bold;
    font-size: 14px;
    border: 1px solid #323136;
  }
  @media screen and (max-width: 1024px) {
    .sports_types {
      flex-wrap: wrap !important;
      justify-content: flex-start;

    }

    .sports_types button {
      width: 33% !important;
      margin-top: 2px;
      margin-left: 1px;
    }
  }

</style>